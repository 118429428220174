import { createContext, useContext, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const ExpandAllContext = createContext({expandAll: true, setExpandAll: (open: boolean) => {}});

export function ExpandAllContextProvider({children}: {children: React.ReactNode}) {
  const [expandAll, setExpandAll] = useState(true);
  return (
    <ExpandAllContext.Provider value={{expandAll, setExpandAll}}>
      {children}
    </ExpandAllContext.Provider>
  );
}

export const useExpandAllContext = () => useContext(ExpandAllContext);
