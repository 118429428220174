import {PullRequestMergeMethod} from './generated/github-graphql';
import {gitHubPullRequest, gitHubClient, adminModeState} from './recoil';
import useRefreshPullRequest from './useRefreshPullRequest';
import {GitMergeIcon} from '@primer/octicons-react';
import {Token, StyledOcticon, ActionList, Text} from '@primer/react';
import cn from 'classnames';
import {useRecoilValue, useRecoilCallback} from 'recoil';

export default function PullRequestMerge({
  plaintext,
  actionItem,
  className,
}: {
  plaintext?: boolean;
  actionItem?: boolean;
  className?: string;
}) {
  const pullRequest = useRecoilValue(gitHubPullRequest);
  const refreshPullRequest = useRefreshPullRequest();
  const isAdminMode = useRecoilValue(adminModeState);

  const mergePullRequest = useRecoilCallback<[], Promise<void>>(
    ({snapshot}) =>
      async () => {
        const clientLoadable = snapshot.getLoadable(gitHubClient);
        if (clientLoadable.state !== 'hasValue' || clientLoadable.contents == null) {
          return Promise.reject('client not found');
        }
        const client = clientLoadable.contents;

        const pullRequestId = snapshot.getLoadable(gitHubPullRequest).valueMaybe()?.id;
        if (pullRequestId == null) {
          return Promise.reject('pull request not found');
        }

        await client.mergePullRequest({
          pullRequestId,
          mergeMethod: PullRequestMergeMethod.Squash,
        });

        refreshPullRequest();
      },
    [refreshPullRequest],
  );

  if (!pullRequest) {
    return null;
  }

  const isMerged = pullRequest.state === 'MERGED';
  if (isMerged) {
    return null;
  }

  const isMergeable =
    isAdminMode &&
    pullRequest.mergeable === 'MERGEABLE' &&
    pullRequest.state === 'OPEN' &&
    (pullRequest.reviewDecision === 'APPROVED' || pullRequest.reviewDecision === null);

  if (!isMergeable) {
    return null;
  }

  if (plaintext) {
    return (
      <button className={className} onClick={mergePullRequest} disabled={!pullRequest}>
        Merge
      </button>
    );
  }

  if (actionItem) {
    return (
      <ActionList.Item onSelect={mergePullRequest} disabled={!pullRequest}>
        <ActionList.LeadingVisual>
          <StyledOcticon icon={GitMergeIcon} />
        </ActionList.LeadingVisual>
        <Text>Merge pull request</Text>
      </ActionList.Item>
    );
  }

  return (
    <button
      className={cn(className, 'pr-merge')}
      onClick={mergePullRequest}
      disabled={!pullRequest}>
      <Token
        size="extralarge"
        text={'Merge'}
        leadingVisual={() => <StyledOcticon icon={GitMergeIcon} size={16} sx={{marginLeft: '0'}} />}
        sx={{
          color: '#57606a',
          backgroundColor: '#fff',
          borderColor: 'rgba(27,31,36,0.15)',
          paddingLeft: '8px',
          paddingRight: '8px',
          userSelect: 'none',
          cursor: 'pointer',
          ':hover': {
            color: '#fff',
            backgroundColor: 'success.fg',
            borderColor: 'success.fg',
            boxShadow: 'none',
          },
        }}
      />
    </button>
  );
}
