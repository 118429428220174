import cn from 'classnames';

export default function DiffStat({additions, deletions}: {additions: number; deletions: number}) {
  const totalChanges = additions + deletions;
  const additionRatio = additions / totalChanges;
  const deletionRatio = deletions / totalChanges;

  const getColorClass = (index: number) => {
    if (additionRatio > 0.9) {
      return 'green';
    }
    if (deletionRatio > 0.9) {
      return 'red';
    }
    const threshold = (index + 1) / 5;
    return additionRatio >= threshold ? 'green' : 'red';
  };

  const meters = Array.from({length: 4}).map((_, index) => (
    <DiffStatBlock key={index} number={index + 1} color={getColorClass(index)} />
  ));

  const lastMeterColorClass = additions === 0 ? 'red' : deletions === 0 ? 'green' : 'gray';

  return (
    <div className="pr-delta">
      <span className="pr-delta-additions">{`+${additions}`}</span>{' '}
      <span className="pr-delta-deletions">{`-${deletions}`}</span>
      <div className="pr-meters">
        {meters}
        <DiffStatBlock number={5} color={lastMeterColorClass} />
      </div>
    </div>
  );
}

function DiffStatBlock({number, color}: {number: number; color: string}) {
  return <div className={cn(`pr-meter pr-meter-${number}`, color)} />;
}
