import type {ReactElement} from 'react';

interface WrapWhenProps {
  wrap: React.FC<React.ReactNode>;
  when: boolean;
  children: ReactElement;
}

const WrapWhen = ({when, wrap, children}: WrapWhenProps) => (when ? wrap(children) : children);

export default WrapWhen;
