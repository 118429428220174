(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("ReactDOM"), require("Recoil"));
	else if(typeof define === 'function' && define.amd)
		define(["React", "ReactDOM", "Recoil"], factory);
	else if(typeof exports === 'object')
		exports["ReviewStack"] = factory(require("React"), require("ReactDOM"), require("Recoil"));
	else
		root["ReviewStack"] = factory(root["React"], root["ReactDOM"], root["Recoil"]);
})(globalThis, (__WEBPACK_EXTERNAL_MODULE__4883__, __WEBPACK_EXTERNAL_MODULE__1845__, __WEBPACK_EXTERNAL_MODULE__1194__) => {
return 